import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollObservable {
    private dataSubject = new BehaviorSubject<number>(0);
    data$ = this.dataSubject.asObservable();

    // Método para actualizar los datos y notificar a los suscriptores
    updateData(data: number): void {
        this.dataSubject.next(data);
    }
}
